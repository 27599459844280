/* eslint-disable react/jsx-key */
import { useEffect, useState } from "react";
import Button from "../../../../common/Button";
import PageItem from "./PageItem";
import ThreeDots from "./ThreeDots";
import RightArrow from "./RightArrow";
import LeftArrow from "./LeftArrow";

const PaginationGroup = ({ totalPage, currentPage, setCurrentPage }) => {
  const [pageItems, setPageItems] = useState([]);

  const getPageItem = (number) => {
    return (
      <PageItem
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        number={number}
      />
    );
  };

  useEffect(() => {
    const pageItemsCopy = [];

    if (totalPage <= 3) {
      for (let i = 1; i <= totalPage; i++) {
        pageItemsCopy.push(getPageItem(i));
      }
    } else {
      pageItemsCopy.push(getPageItem(currentPage));
      if (currentPage < totalPage) {
        pageItemsCopy.push(getPageItem(totalPage));

        if (currentPage + 1 === totalPage) {
          pageItemsCopy = [
            getPageItem(currentPage - 1),
            <ThreeDots />,
            ...pageItemsCopy,
          ];
        } else if (currentPage % 2 === 1) {
          pageItemsCopy.splice(1, 0, getPageItem(currentPage + 1));
          pageItemsCopy.splice(2, 0, <ThreeDots />);
        } else {
          pageItemsCopy.splice(0, 0, getPageItem(currentPage - 1));
          pageItemsCopy.splice(2, 0, <ThreeDots />);
        }
      } else {
        pageItemsCopy = [
          getPageItem(1),
          getPageItem(2),
          <ThreeDots />,
          ...pageItemsCopy,
        ];
      }
    }

    setPageItems(pageItemsCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPage, currentPage]);

  return (
    <>
      <Button
        type="active"
        additionStyle={`!p-[7px] w-[25px] h-[25px] md:w-[27px] md:h-[27px] border-transparent bg-[#5028BC25] ${
          currentPage === totalPage && "bg-[#eee] cursor-not-allowed"
        }`}
        onClick={() => {
          if (currentPage < totalPage) {
            setCurrentPage(currentPage + 1);
          }
        }}
      >
        <LeftArrow currentPage={currentPage} totalPage={totalPage} />
      </Button>
      <Button
        type="active"
        additionStyle={`!p-[7px] w-[25px] h-[25px] md:w-[27px] md:h-[27px] bg-[#5028BC25] border-transparent ${
          currentPage === 1 && "bg-[#eee] cursor-not-allowed"
        }`}
        onClick={() => {
          if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
          }
        }}
      >
        <RightArrow currentPage={currentPage} totalPage={totalPage} />
      </Button>
    </>
  );
};

export default PaginationGroup;
