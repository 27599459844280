import Button from "../../../../common/Button";

const PageItem = ({ setCurrentPage, currentPage, additionalStyle, number }) => {
  return (
    <Button
      onClick={() => {
        setCurrentPage(number);
      }}
      type="active"
      className={`min-w-[25px] h-[25px] md:min-w-[27px] md:h-[27px] rounded-full !p-0 border-[1px] border-[#5028BC50] bg-[#5028BC25] text-[75%] !px-[3px] truncate ${additionalStyle} ${
        currentPage === number && "bg-[#5038BCBB] text-white"
      }`}
    >
      {number}
    </Button>
  );
};

export default PageItem;
