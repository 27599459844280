import { useRouter } from "next/router";
import HeaderWrapper from "./HeaderWrapper";
import Logo from "./Logo";
import ShortenerInput from "./ShortenerInput";

const URLShortener = () => {
  const router = useRouter();

  return (
    <>
      <Logo />
      <HeaderWrapper>
        <h2>
          The <span style={{ color: "#5038BC" }}>easiest</span> customizable
        </h2>
        <h2>
          URL shortener, <span style={{ color: "#5038BC" }}>ever</span>.
        </h2>
      </HeaderWrapper>
      <ShortenerInput />
      <div className="mt-8 text-center">
        By using RISTEK LINK, you agree to our{" "}
        <span
          className="font-black text-[#5038BC] cursor-pointer"
          onClick={() => {
            router.push("terms-of-service");
          }}
        >
          Terms of Service
        </span>
      </div>
    </>
  );
};

export default URLShortener;
