import Button from "../../../common/Button";

const ButtonChoicesGroup = ({ onClick, analyticState }) => {
  return (
    <>
      <Button
        id="visitor"
        onClick={onClick}
        type={analyticState === "visitor" ? "active" : "ghost"}
        additionStyle={`${
          analyticState === "visitor"
            ? "bg-[#64E6FB40] border-0"
            : "bg-transparent"
        }`}
      >
        Total Clicks
      </Button>
      <Button
        id="unique_visitor"
        onClick={onClick}
        type={analyticState === "unique_visitor" ? "active" : "ghost"}
        additionStyle={`${
          analyticState === "unique_visitor"
            ? "bg-[#64E6FB40] border-0"
            : "bg-transparent"
        }`}
      >
        Unique Visitors
      </Button>
      <Button
        id="retentions"
        // onClick={onClick}
        type={analyticState === "retentions" ? "active" : "ghost"}
        additionStyle={`cursor-not-allowed ${
          analyticState === "retentions"
            ? "bg-[#64E6FB40] border-0"
            : "bg-transparent"
        }`}
      >
        Retentions
      </Button>
    </>
  );
};

export default ButtonChoicesGroup;
