const StyledInput = ({
  onFocus,
  onBlur,
  error,
  onChange,
  value,
  placeholder,
  disabled = false,
}) => {
  return (
    <input
      type="text"
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      className={`${error ? "border-red" : "border-transparent"} ${
        disabled ? "bg-[#E0E0E0]" : ""
      } border-2 appearance-none  rounded md:rounded-md h-1 w-full p-4 py-6 font-normal shadow-sm focus:outline focus:outline-2 focus:outline-[#5038BC] focus:outline-offset-2 focus:border-transparent`}
    ></input>
  );
};

export const Container = ({ userTyping, showTooltip, children }) => {
  return (
    <div
      style={{
        transformOrigin: `${showTooltip && "0 0"}`,
      }}
      before="Short URLs are case senstive. Make sure you type the right one!"
      className={`w-full h-full ${
        showTooltip &&
        `before:pointer-events-none before:flex before:absolute before:content-[attr(before)] before:text-[12px] before:font-bold before:items-center before:text-center before:rounded-[10px] before:p-[10px] before:h-[58px] before:w-[246px] before:bg-[#DED9F2] before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-[125%] after:pointer-events-none after:content-[''] after:absolute after:w-[16px] after:h-[16px] after:top-0 after:left-1/2 after:bg-[#ded9f2] after:rotate-45 after:-translate-x-[125%] after:-translate-y-[145%] ${
          !userTyping && "after:animate-fadeout before:animate-fadeout"
        }`
      }`}
    >
      {children}
    </div>
  );
};

const Input = (props) => {
  return (
    <Container userTyping={props.userTyping} showTooltip={props.showTooltip}>
      <StyledInput
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        error={props.error}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
    </Container>
  );
};

export default Input;
