const LeftArrow = ({ currentPage, totalPage }) => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99997 0L7.40997 1.41L2.82997 6L7.40997 10.59L5.99997 12L-2.67029e-05 6L5.99997 0Z"
        fill={`${currentPage === totalPage ? "#aaa" : "#5038BC"}`}
      />
    </svg>
  );
};

export default LeftArrow;
