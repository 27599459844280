import { useEffect, useState } from "react";
import { useStateManagementContext } from "../../../../../context/StateManagementContext/StateManagementContext";
import WarningIcon from "../../../../common/Icon/WarningIcon";
import ExportIcon from "./ExportIcon";
import { TriangleIconDown, TriangleIconUp } from "./TriangleIcon";

const DetailInfo = ({ dataset }) => {
  const [range, setRange] = useState("7 days");
  const { rangeState } = useStateManagementContext();
  useEffect(() => {
    switch (rangeState) {
      case "six_month":
        setRange("6 months");
        break;
      case "month":
        setRange("1 month");
        break;
      default:
        setRange("7 days");
    }
  }, [rangeState]);

  const growth =
    dataset?.totalCurrentPeriod - dataset?.totalPreviousPeriod || 0;
  let prefix = "+";
  if (growth < 0) prefix = "";

  return (
    <div className="ml-4 flex text-[12px] items-center gap-2">
      {growth >= 0 ? <TriangleIconUp /> : <TriangleIconDown />}
      <span className={growth >= 0 ? "text-[#27AE60]" : "text-[#CC252E]"}>
        {dataset?.percentage || "0"}%
      </span>{" "}
      <div className="relative h-full group cursor-pointer text-[14px]">
        <WarningIcon size={16} />
        <div className="w-[10px] h-[10px] hidden absolute group-hover:block border-2 border-[#5038BC22] rotate-45 sm:left-1"></div>
        <div className="hidden group-hover:block absolute w-[200px] p-2 bg-white border-2 border-[#5038BC22] rounded-md top-[20px] right-0 sm:left-0">
          <span className={growth >= 0 ? "text-[#27AE60]" : "text-[#CC252E]"}>
            {prefix + growth || "-"} (
            {dataset?.percentage ? dataset.percentage + "%" : "0%"}){" "}
          </span>
          total clicks from last {range}
        </div>
      </div>
      {/* <div className="relative h-full group cursor-pointer text-[14px] font-normal">
        <ExportIcon size={16} />
        <div className="w-[10px] h-[10px] hidden absolute group-hover:block rotate-45	sm:left-1 bg-[#DED9F2]"></div>
        <div className="hidden group-hover:block absolute w-[120px] text-center p-2 bg-[#DED9F2] rounded-md top-[20px] -right-1/2 sm:-translate-x-1/2 sm:left-1/2">
          Download Analytics
        </div>
      </div> */}
    </div>
  );
};

export default DetailInfo;
