const DetailSummary = ({ name, value }) => {
  return (
    <div
      className="flex flex-col lg:flex-row gap-2 items-start lg:items-center p-3 rounded-md rounded-md text-[0.6em] sm:text-[0.7em] md:text-[0.8em] max-w-[13em] sm:max-w-[12em]"
      style={{ backgroundColor: "#DED9F2" }}
    >
      <h2 className="font-black">{name}</h2>
      <h1 style={{ color: "#5038BC" }} className="font-black text-[1.4em]">
        {value}
      </h1>
    </div>
  );
};

export default DetailSummary;
