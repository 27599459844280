/* eslint-disable react-hooks/exhaustive-deps */
import BackSection from "../GenerateQR/BackSection";
import AnalyticContainer from "./components/AnalyticContainer";
import HeaderWrapper from "./components/HeaderWrapper";
import ToggleButtonWrapper from "./components/ToggleButtonWrapper";
import AnalyticCard from "./components/AnalyticCard/AnalyticCard";
import ButtonChoicesGroup from "./components/ButtonChoicesGroup";
import { useStateManagementContext } from "../../../context/StateManagementContext/StateManagementContext";
import { useEffect, useState } from "react";
import axios from "axios";
import AboutRetentionAnalytics from "./components/Retentions/AboutRetentionAnalytics";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import useWindowSize from "../../../hooks/useWindowSize";

const Analytic = () => {
  const {
    state,
    setState,
    analyticState,
    setAnalyticState,
    rangeState,
    setRangeState,
  } = useStateManagementContext();
  const { dataLink } = useStateManagementContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [dataset, setDataset] = useState(null);
  const { user } = useAuthContext();
  const { width } = useWindowSize();

  const getDataset = async () => {
    await axios
      .get(
        `/api/analytic?email=${user.email}&shorten=${dataLink.shorten}&category=${analyticState}&page=${currentPage}&range=${rangeState}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          res.data.convertedLabel = convertLabel(
            res.data.label,
            rangeState,
            width
          );
          res.data.convertedData = res.data.data.reverse();
          setDataset(res.data);
          setTotalPage(res.data.totalPage);
        }
      })
      .catch((_err) => {
        setDataset([]);
        setTotalPage(1);
      });
  };

  useEffect(() => {
    getDataset();
  }, [currentPage, rangeState, analyticState, dataLink]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rangeState, analyticState]);

  const changeAnalyticStateHandler = (e) => {
    setAnalyticState(e.target.id);
  };

  const changeRangeStateHandler = (e) => {
    setRangeState(e.target.id);
  };

  return (
    <div className="w-full flex flex-col justify-center items-center px-2">
      <AnalyticContainer>
        {state === "about-retention" ? (
          <BackSection
            additionStyles={"px-[24px] lg:px-0"}
            text="Back to Analytic"
            onClick={() => {
              setState("analytic");
            }}
          />
        ) : (
          <BackSection
            text="Back to Home screen"
            additionStyles={"px-[24px] lg:px-0"}
          />
        )}
        {state === "about-retention" ? (
          <HeaderWrapper>
            <h1 className="text-center">
              About Retention <span className="text-[#5038BC]">Analytics</span>
            </h1>
          </HeaderWrapper>
        ) : (
          <HeaderWrapper>
            <h1 className="text-center">
              See Your <span className="text-[#5038BC]">Analytics</span>
            </h1>
          </HeaderWrapper>
        )}
        {state === "analytic" && (
          <ToggleButtonWrapper>
            <ButtonChoicesGroup
              onClick={changeAnalyticStateHandler}
              analyticState={analyticState}
            />
          </ToggleButtonWrapper>
        )}
        {state === "about-retention" ? (
          <AboutRetentionAnalytics dataLink={dataLink} />
        ) : (
          <AnalyticCard
            rangeState={rangeState}
            changeRangeStateHandler={changeRangeStateHandler}
            dataset={dataset}
            totalPage={totalPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            dataLink={dataLink}
            analyticState={analyticState}
          />
        )}
      </AnalyticContainer>
    </div>
  );
};

const convertLabel = (labels, range, width) => {
  let newLabel = [];
  if (range === "week") {
    labels.forEach((label) => {
      newLabel.push(convertWithWeekFormat(label, width));
    });
    return newLabel.reverse();
  } else if (range === "month") {
    labels.forEach((label) => {
      const [startDate, endDate] = label.split(" - ");
      newLabel.push(
        `${convertWithMonthFormat(startDate)}-${convertWithMonthFormat(
          endDate
        )}`
      );
    });
    return newLabel.reverse();
  } else if (range === "six_month") {
    labels.forEach((label) => {
      newLabel.push(convertWithSixMonthFormat(label));
    });
    return newLabel.reverse();
  }

  return labels.reverse();
};

const convertWithWeekFormat = (label, width) => {
  const date = new Date(label);
  let daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  if (width < 768) {
    daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  }
  const dayOfWeekNumeric = date.getDay();
  return daysOfWeek[dayOfWeekNumeric];
};

const convertWithMonthFormat = (label) => {
  const [_year, month, day] = label.split("-");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthName = monthNames[parseInt(month) - 1];
  return `${day} ${monthName}`;
};

const convertWithSixMonthFormat = (label) => {
  const [month, _year] = label.split(" ");
  return month;
};

export default Analytic;
