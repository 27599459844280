/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import axios from "axios";
import { Spinner } from "@chakra-ui/react";
import { useDrawerContext } from "../../../context/DrawerContext/DrawerContext";
import {
  useAuthContext,
  AuthStatus,
} from "../../../context/AuthContext/AuthContext";
import { Alert, AlertIcon } from "@chakra-ui/react";
import { useEffect } from "react";
import useCustomToast from "../../utils/useCustomToast";
import Button from "../../common/Button";
import Input from "../../common/Input/Input";
import { useMixpanelClient } from "../../../hooks/useMixpanelClient";

const ShortenerInput = () => {
  const [userTyping, setUserTyping] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { user, loggingIn, authenticate } = useAuthContext();
  const { refreshUrls, setUrls, setDrawerOpened } = useDrawerContext();
  const [submitting, setSubmitting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [urlAllowed, setUrlAllowed] = useState();
  const [shortenedUrlAllowed, setShortenedUrlAllowed] = useState();
  const [url, setUrl] = useState("");
  const [shortenedUrl, setShortenedUrl] = useState("");
  const toast = useCustomToast();
  const [hasTypedInLongLink, setHasTypedInLongLink] = useState(false);
  const [hasTypedInShortLink, setHasTypedInShortLink] = useState(false);

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (urlAllowed || !url.length) {
        setShowAlert(false);
      } else if (urlAllowed !== undefined) {
        setShowAlert(true);
      }
    }, 1000);

    return () => {
      clearTimeout(debounce);
      setShowAlert(false);
    };
  }, [url]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      if (shortenedUrl.length !== 0) {
        setUserTyping(false);
      } else {
        setUserTyping(true);
      }
    }, 800);

    return () => {
      clearTimeout(debounce);
      if (shortenedUrl.length !== 0) {
        setUserTyping(true);
      }
    };
  }, [shortenedUrl]);

  const handleSubmit = async () => {
    useMixpanelClient.track('shorten_link', {
      "is_logged_in": user ? "True" : "False"
    })
    const email = user?.email;
    const authStatus = await authenticate();
    if (shortenedUrlAllowed && urlAllowed) {
      setSubmitting(true);
      await axios
        .post("/api/shorten", {
          email: email,
          shorten: shortenedUrl,
          url: url,
        })
        .then((res) => res.data.message)
        .then(async (message) => {
          toast({
            title: "Success!",
            message: "Your URL has been successfully shortened.",
            type: "success",
          });
          if (user) {
            if (
              authStatus === AuthStatus.SESSION_EXPIRED ||
              authStatus === AuthStatus.TOKEN_UNVERIFIED
            ) {
              setUrls(() => [
                {
                  id: 1,
                  shorten: message.shorten,
                  url: message.url,
                  createdDate: {
                    _seconds: new Date().getTime() / 1000,
                  },
                },
              ]);
            } else {
              await refreshUrls();
            }
          } else {
            setUrls((prev) => [
              {
                id: prev.length + 1,
                shorten: message.shorten,
                url: message.url,
                createdDate: {
                  _seconds: new Date().getTime() / 1000,
                },
              },
              ...prev,
            ]);
            toast({
              title: "Sign in required",
              message: "You need to sign in to be able to edit your link.",
              status: "warning",
            });
          }
          setDrawerOpened(true);
          setShortenedUrl("");
          setUrl("");
          setUrlAllowed();
          setShortenedUrlAllowed();
          setShowAlert(false);
          return;
        })
        .catch((err) => {
          toast({
            title: "Failed.",
            message: err.response.data.message,
            type: "error",
          });
          return;
        });
      setSubmitting(false);
    }
  };

  const handleUrlChange = (e) => {
    const inputUrl = e.currentTarget.value;
    if (!hasTypedInLongLink) {
      useMixpanelClient.track('input_long_link')
      setHasTypedInLongLink(true);
    }
    setUrl(inputUrl);
    const urlRegexPattern =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(urlRegexPattern);

    if (inputUrl.match(regex) && inputUrl.split(" ").length === 1) {
      setUrlAllowed(true);
    } else {
      setUrlAllowed(false);
    }
  };

  const handleShortenedUrlChange = (e) => {
    const inputUrl = e.currentTarget.value;
    if (!hasTypedInShortLink) {
      useMixpanelClient.track('input_short_link')
      setHasTypedInShortLink(true);
    }
    setShortenedUrl(
      inputUrl.split(" ").length > 1 ? inputUrl.split(" ").join("-") : inputUrl
    );
    if (!inputUrl.length) {
      setShortenedUrlAllowed(false);
    } else {
      setShortenedUrlAllowed(true);
    }
  };

  return (
    <div className="w-full max-w-lg flex flex-col items-center relative">
      {showAlert && (
        <Alert
          fontWeight={"600"}
          fontSize={"0.8rem"}
          marginBottom={"0.5rem"}
          borderRadius={"5px"}
          status="warning"
        >
          <AlertIcon />
          Please enter a valid URL (www.google.com, https://google.com, ...)
        </Alert>
      )}
      <Input
        placeholder="Enter your long URL"
        onChange={handleUrlChange}
        value={url}
      />
      <div className="my-5 flex flex-row items-center w-full justify-center relative">
        <div className="font-bold mr-3">ristek.link/</div>
        <Input
          onFocus={() => setShowTooltip(true)}
          onBlur={() => setShowTooltip(false)}
          showTooltip={showTooltip}
          userTyping={userTyping}
          placeholder="Enter your short URL"
          onChange={handleShortenedUrlChange}
          value={shortenedUrl}
        />
      </div>
      <Button
        type={
          urlAllowed && shortenedUrlAllowed && !submitting && !loggingIn
            ? "primary"
            : "stale"
        }
        disabled={
          !(urlAllowed && shortenedUrlAllowed && !submitting && !loggingIn)
        }
        onClick={handleSubmit}
      >
        <div className="font-semibold flex content-center">
          {submitting ? <Spinner /> : <>Shorten!</>}
        </div>
      </Button>
    </div>
  );
};

export default ShortenerInput;
