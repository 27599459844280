const Logo = (props) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 269 268"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_68_2754)">
        <circle r="134" transform="matrix(-1 0 0 1 0 134)" fill="#5038BC" />
      </g>
      <g clipPath="url(#clip1_68_2754)">
        <path d="M269 3.99999L135 138L269 138L269 3.99999Z" fill="#64E6FB" />
      </g>
      <circle cx="68" cy="201" r="49" stroke="#FFD668" strokeWidth="36" />
      <circle cx="68" cy="201" r="49" stroke="#FFD668" strokeWidth="36" />
      <g clipPath="url(#clip2_68_2754)">
        <path d="M135 134L269 268L269 134L135 134Z" fill="#C424A3" />
        <path
          d="M269.278 268.279L135.278 134.279L135.278 268.279L269.278 268.279Z"
          fill="#5038BC"
        />
        <circle cx="202" cy="201" r="45" fill="#FFD668" />
      </g>
      <defs>
        <clipPath id="clip0_68_2754">
          <rect
            width="134"
            height="134"
            fill="white"
            transform="matrix(-1 0 0 1 134 0)"
          />
        </clipPath>
        <clipPath id="clip1_68_2754">
          <rect
            width="134"
            height="134"
            fill="white"
            transform="translate(269 4) rotate(90)"
          />
        </clipPath>
        <clipPath id="clip2_68_2754">
          <rect
            width="134"
            height="134"
            fill="white"
            transform="translate(135 134)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const InfoLogo = () => {
  return (
    <svg
      style={{ position: "absolute", right: 0, transform: "translateX(200%)" }}
      width="clamp(20px, 1.5vw, 25px)"
      height="clamp(21px, 1.5vw, 26px)"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9 5.5H11V7.5H9V5.5ZM9 9.5H11V15.5H9V9.5Z" fill="#323232" />
      <path
        d="M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5Z"
        fill="#323232"
      />
    </svg>
  );
};

export default Logo;
