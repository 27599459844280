const HeaderWrapper = ({ children }) => {
  return (
    <div
      style={{ fontSize: "clamp(1.2rem, 6vw, 2.25rem)" }}
      className="max-w-[550px] font-bold leading-10 my-8 mx-0 text-center"
    >
      {children}
    </div>
  );
};

export default HeaderWrapper;
