const RightArrow = ({ currentPage }) => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z"
        fill={`${currentPage === 1 ? "#aaa" : "#5038BC"}`}
      />
    </svg>
  );
};

export default RightArrow;
