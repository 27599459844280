const ToggleSwitch = ({ value, onChange }) => {
  return (
    <div>
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          value={value}
          onChange={onChange}
          className="sr-only peer"
          checked={value}
        />
        <div className="w-11 h-6 ring-2 ring-black rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-[#5038BC] peer-checked:after:bg-[#5038BC] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-[#5038BC] after:border after:border-2 after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-black peer-checked:bg-[#fff]"></div>
      </label>
    </div>
  );
};

export default ToggleSwitch;
