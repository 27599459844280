import Input from "../../common/Input/Input";

const UrlSection = ({ url, shorten }) => {
  return (
    <div className="w-full mt-8 flex flex-col">
      <Input value={url} disabled={true} />
      <div className="mt-5 flex flex-row items-center w-full justify-center relative">
        <div className="font-bold mr-3">ristek.link/</div>
        <Input value={shorten} disabled={true} />
      </div>
    </div>
  );
};

export default UrlSection;
