import axios from "axios";
import { ThreeCircle } from "./Logo";
import { useDrawerContext } from "../../../context/DrawerContext/DrawerContext";
import { useEffect, useRef, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import { toPng } from "html-to-image";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";
import useCustomToast from "../../utils/useCustomToast";
import download from "downloadjs";

import Button from "../../common/Button";
import CopyIcon from "../../common/Icon/CopyIcon";
import PngDownloadIcon from "../../common/Icon/PngDownloadIcon";
import { useMixpanelClient } from "../../../hooks/useMixpanelClient";

const GenerateOption = ({
  openFloating,
  setOpenFloating,
  openFloatingHandler,
  shorten,
  withLogo,
  color,
}) => {
  const { user } = useAuthContext();
  const { refreshUrls } = useDrawerContext();
  const [pngIsLoading, setPngIsLoading] = useState(false);
  const [svgIsLoading, setSvgIsLoading] = useState(false);
  const [copyIsLoading, setCopyIsLoading] = useState(false);
  const [data, setData] = useState("");
  const toast = useCustomToast();
  const divRef = useRef(null);

  const downloadImage = async () => {
    toPng(divRef.current)
      .then((dataUrl) => {
        setData("");
        download(dataUrl, `${shorten}.png`);
        setPngIsLoading(false);
        toast({
          type: "success",
          title: "Generate QR Code successful!",
          message: `Your QR Code has been successfully generated`,
        });
      })
      .catch((error) => {
        toast({
          type: "error",
          title: "Oops, something went wrong!",
          message: error,
        });
      });
  };

  const copyImage = async () => {
    toPng(divRef.current)
      .then((dataUrl) => {
        setData("");
        const blob = dataURLToBlob(dataUrl);
        navigator.clipboard.write([new ClipboardItem({ "image/png": blob })]);
        setCopyIsLoading(false);
        toast({
          type: "info",
          title: "Copied to clipboard.",
          message: `QR Code has been copied to your clipboard.`,
        });
      })
      .catch((error) => {
        toast({
          type: "error",
          title: "Oops, something went wrong!",
          message: error,
        });
      });
  };

  function dataURLToBlob(dataURL) {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uint8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uint8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uint8Array], { type: contentType });
  }

  useEffect(() => {
    if (!data) {
      return;
    }

    if (pngIsLoading) {
      downloadImage();
    } else if (copyIsLoading) {
      copyImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const pngHandler = async () => {
    useMixpanelClient.track("download_qr");
    setPngIsLoading(true);
    let svgString = await generateQRHandler();
    setData(svgString);
  };

  const copyHandler = async () => {
    useMixpanelClient.track("copy_qr");
    setCopyIsLoading(true);
    let svgString = await generateQRHandler();
    setData(svgString);
  };

  const svgHandler = async () => {
    useMixpanelClient.track("download_qr");
    setSvgIsLoading(true);
    const svgString = await generateQRHandler();

    if (svgString === null) {
      return;
    }

    var blob = new Blob([svgString], {
      type: "image/svg+xml;charset=utf-8",
    });
    download(blob, `${shorten}.svg`);
    toast({
      type: "success",
      title: "Generate QR Code successful!",
      message: `Your QR Code has been successfully generated`,
    });
    setSvgIsLoading(false);
    setOpenFloating(false);
  };

  const generateQRHandler = async () => {
    return await axios
      .patch("/api/generateQR", {
        email: user?.email,
        shorten: shorten,
        color: color,
        with_logo: withLogo,
      })
      .then(async (res) => {
        if (res.status == 200) {
          await refreshUrls();
          return res.data.message.qrCode;
        }

        return null;
      })
      .catch((err) => {
        const response = err.response;
        const data = response.data.message;

        toast({
          type: "error",
          title: "Error when Generating QR Code",
          message: data,
        });
        setPngIsLoading(false);
        setCopyIsLoading(false);
        setSvgIsLoading(false);
        return null;
      });
  };

  return (
    <div className="w-full flex flex-col mt-8 font-bold relative">
      <div>Generate Your QR Code as</div>
      <div
        className={`flex gap-3 mt-3 items-center ${
          data?.length > 0 ? "mb-[40em]" : ""
        }`}
      >
        <Button
          type={`${pngIsLoading ? "dead" : "primary"}`}
          onClick={pngHandler}
          disabled={pngIsLoading}
        >
          <div className="flex gap-2 items-center">
            {pngIsLoading ? (
              <Spinner />
            ) : (
              <>
                <PngDownloadIcon /> PNG
              </>
            )}
          </div>
        </Button>
        <Button
          type={`${copyIsLoading ? "dead" : "outlined"}`}
          onClick={copyHandler}
          disabled={copyIsLoading}
        >
          <div className="flex gap-2 items-center">
            {copyIsLoading ? (
              <Spinner />
            ) : (
              <>
                <CopyIcon /> Copy
              </>
            )}
          </div>
        </Button>
        <div className="!relative" onClick={openFloatingHandler}>
          <Button type={"ghost"} additionStyle={"!px-0 !h-full"}>
            <ThreeCircle />
          </Button>
          <div
            className={`absolute ${
              openFloating ? "flex" : "hidden"
            } text-[#5028BC] text-[0.9em]`}
            disabled={svgIsLoading}
          >
            <Button
              onClick={svgHandler}
              type={`${svgIsLoading ? "ghost" : "outlined"}`}
              additionStyle={`p-2 text-[0.9em] border-0 bg-white`}
              disabled={svgIsLoading}
            >
              {svgIsLoading ? <Spinner /> : "Download as SVG"}
            </Button>
          </div>
        </div>
      </div>
      <div ref={divRef} dangerouslySetInnerHTML={{ __html: data }}></div>
    </div>
  );
};

export default GenerateOption;
