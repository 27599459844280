import { useStateManagementContext } from "../../../../../context/StateManagementContext/StateManagementContext";
import WarningIcon from "../../../../common/Icon/WarningIcon";
import BarChart from "../Chart/BarChart";
import LineChart from "../Chart/LineChart";
import PaginationGroup from "../Pagination/PaginationGroup";
import PaginationWrapper from "../Pagination/PaginationWrapper";
import QuestionIcon from "../Retentions/QuestionIcon";
import CardHeader from "./CardHeader";
import DetailInfo from "./DetailInfo";
import DetailSummaryGroup from "./DetailSummaryGroup";
import DetailSummaryWrapper from "./DetailSummaryWrapper";
import InfoDetailHeader from "./InfoDetailHeader";
import PeriodeInfo from "./PeriodeInfo";
import RangeButtonGroup from "./RangeButtonGroup";
import RangeButtonWrapper from "./RangeButtonWrapper";
import UpperCardHeader from "./UpperCardHeader";

const AnalyticCard = ({
  additionalStyle,
  rangeState,
  changeRangeStateHandler,
  dataset,
  totalPage,
  setCurrentPage,
  currentPage,
  dataLink,
  analyticState,
}) => {
  const { setState } = useStateManagementContext();
  return (
    <div
      className={`rounded-md box-border w-full bg-white mb-7 mt-4 ${additionalStyle}`}
      style={{
        border: "2px solid rgba(80, 56, 188, 0.2)",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <CardHeader>
        <UpperCardHeader>
          <InfoDetailHeader>
            <h1 className="text-start text-[1.3em] sm:text-[1.3em] md:text-[1.4em] sm:text-start flex items-center">
              <span style={{ color: "#5038BC", marginRight: "5px" }}>
                {Number(dataset?.totalCurrentPeriod ?? 0)
                  .toLocaleString("en-US")
                  .replace(",", ".")}
              </span>
              {analyticState === "visitor"
                ? "Total Clicks"
                : analyticState === "unique_visitor"
                ? "Unique Visitors"
                : "Retentions"}
              <DetailInfo dataset={dataset} />
            </h1>
            <h1 className="text-start text-[0.85em] sm:text-[0.85em] md:text-[1em] sm:text-start truncate">
              From{" "}
              <span style={{ color: "#5038BC" }}>
                ristek.link/{dataLink?.shorten}
              </span>
            </h1>
          </InfoDetailHeader>
          <RangeButtonWrapper>
            <RangeButtonGroup
              rangeState={rangeState}
              onClick={changeRangeStateHandler}
            />
          </RangeButtonWrapper>
        </UpperCardHeader>
        <PeriodeInfo>
          <WarningIcon size={15} />
          {dataset && !Array.isArray(dataset) ? (
            <div>{`Period 
              ${convertDateString(
                dataset?.label[dataset?.label?.length - 1] ?? ""
              )} - ${convertDateString(dataset?.label[0] ?? "", true)}`}</div>
          ) : (
            <div>Periode -</div>
          )}
        </PeriodeInfo>
      </CardHeader>
      <hr style={{ backgroundColor: "#E5E5E5" }} />
      {analyticState === "retentions" ? (
        <LineChart dataset={dataset} />
      ) : (
        <BarChart dataset={dataset} />
      )}
      <PaginationWrapper>
        <PaginationGroup
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </PaginationWrapper>
      <DetailSummaryWrapper>
        {analyticState === "retentions" ? (
          <div
            className="flex gap-2 items-center px-3 py-4 rounded-md rounded-md text-[0.7em] sm:text-[0.8em] md:text-[0.9em]"
            style={{ backgroundColor: "#DED9F2" }}
          >
            <QuestionIcon />
            See what is retention
            <span
              className="-ml-1 text-[#3882D6] font-bold underline cursor-pointer"
              onClick={() => {
                setState("about-retention");
              }}
            >
              here
            </span>
          </div>
        ) : (
          <DetailSummaryGroup dataset={dataset} />
        )}
      </DetailSummaryWrapper>
    </div>
  );
};

const convertDateString = (dateString, last = false) => {
  const dateParts = dateString.split("-");

  if (dateParts.length === 1) {
    return dateString;
  }

  let year, month, day;

  if (dateParts.length > 3 && last) {
    year = parseInt(dateParts[3]);
    month = parseInt(dateParts[4]);
    day = parseInt(dateParts[5]);
  } else {
    year = parseInt(dateParts[0]);
    month = parseInt(dateParts[1]);
    day = parseInt(dateParts[2]);
  }

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedDate = day + " " + monthNames[month - 1] + " " + year;

  return formattedDate;
};

export default AnalyticCard;
