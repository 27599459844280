import { useEffect, useState } from "react";
import { hexToCSSFilter } from "hex-to-css-filter";
import BackSection from "./BackSection";
import Preview from "./Preview";
import QROption from "./QROption";
import GenerateOption from "./GenerateOption";
import UrlSection from "./UrlSection";
import { useStateManagementContext } from "../../../context/StateManagementContext/StateManagementContext";

const GenerateQR = () => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [color, setColor] = useState("#000");
  const [withLogo, setWithLogo] = useState(false);
  const [filter, setFilter] = useState(color);
  const [openFloating, setOpenFloating] = useState();
  const { dataLink } = useStateManagementContext();
  const [url, setUrl] = useState(dataLink.url);
  const [shorten, setShorten] = useState(dataLink.shorten);

  useEffect(() => {
    if (dataLink.withLogo) {
      setWithLogo(dataLink.withLogo);
    }

    if (dataLink.qrCode) {
      var re = /#[0-9a-f]{3,6}/gi;
      var colors = dataLink.qrCode.match(re);
      try {
        setColor(colors[1]);
      } catch (error) {}
    }

    setUrl(dataLink.url);
    setShorten(dataLink.shorten);
  }, [dataLink]);

  useEffect(() => {
    const curFilter = hexToCSSFilter(color).filter.toString();
    setFilter(curFilter.slice(0, curFilter.length - 1));
  }, [color]);

  const openColorPickerHandler = (e) => {
    setShowColorPicker(true);
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
  };

  const openFloatingHandler = (e) => {
    setOpenFloating(true);
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
  };

  return (
    <div
      className="w-full flex justify-center"
      onClick={() => {
        setShowColorPicker(false);
        setOpenFloating(false);
      }}
    >
      <div className="mt-[56px] lg:mt-[80px] py-[30px] pb-[70px] flex flex-col items-start justify-center w-full lg:px-5 max-w-[600px]">
        <BackSection text="Back to Home screen" />
        <div
          className={`mt-8 text-[1.4em] sm:text-[1.5em] lg:text-[1.7em] font-black flex justify-center w-full mt-8`}
        >
          <h1 className="text-center">
            Generate Your <span className="text-[#5038BC]">QR Code</span>
          </h1>
        </div>
        <UrlSection url={url} shorten={shorten} />
        <div className="w-full flex flex-col mt-8 gap-5 lg:gap-5 lg:flex-row items-start justify-between">
          <QROption
            color={color}
            setColor={setColor}
            showColorPicker={showColorPicker}
            openColorPickerHandler={openColorPickerHandler}
            withLogo={withLogo}
            setWithLogo={setWithLogo}
          />
          <Preview color={color} filter={filter} withLogo={withLogo} />
        </div>
        <GenerateOption
          openFloating={openFloating}
          setOpenFloating={setOpenFloating}
          openFloatingHandler={openFloatingHandler}
          shorten={dataLink.shorten}
          withLogo={withLogo}
          color={color}
        />
      </div>
    </div>
  );
};

export default GenerateQR;
