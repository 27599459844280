import AboutRetentionDescription from "./AboutRetentionDescription";
import AboutRetentionList from "./AboutRetentionList";
import AboutRetentionTitle from "./AboutRetentionTitle";

const AboutRetentionAnalytics = () => {
  return (
    <div
      className={`rounded-md box-border w-full bg-white mb-7 mt-4 p-10`}
      style={{
        border: "2px solid rgba(80, 56, 188, 0.2)",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <ul className="flex flex-col gap-8 ">
        <AboutRetentionList>
          <AboutRetentionTitle>
            What is Retention Analytic ?
          </AboutRetentionTitle>
          <AboutRetentionDescription>
            RISTEK.LINK Analytic is unbounded retention to measure the
            percentage of users who came back on a specific day or any day after
            that.
          </AboutRetentionDescription>
        </AboutRetentionList>
        <AboutRetentionList>
          <AboutRetentionTitle>
            How to read Retention Analytic data ?
          </AboutRetentionTitle>
          <AboutRetentionDescription>
            A “retention” is counted for a specific day X (e.g. 7 day
            retention), when a user comes back at X days or after X days since
            they first opened the link or we can say unbounded retention is
            percentage of users who came back on a specific period of time. See
            more about unbounded retention here
            <a
              href="https://ristek.link/unbounded-retention"
              className="ml-1 text-[#3882D6]"
              target="_blank"
              rel="noreferrer"
            >
              ristek.link/unbounded-retention
            </a>
          </AboutRetentionDescription>
        </AboutRetentionList>
      </ul>
    </div>
  );
};

export default AboutRetentionAnalytics;
