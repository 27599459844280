const AnalyticContainer = ({ additionalStyle, children }) => {
  return (
    <div
      className={`mt-[56px] lg:mt-[80px] py-[30px] pb-[70px] flex flex-col items-start justify-center w-full box-border lg:px-5 max-w-[800px] ${additionalStyle}`}
    >
      {children}
    </div>
  );
};

export default AnalyticContainer;
