const PngDownloadIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 10H15V4H9V10H5L12 17L19 10ZM11 12V6H13V12H14.17L12 14.17L9.83 12H11ZM19 21V19H5V21H19Z"
        fill="white"
      />
    </svg>
  );
};

export default PngDownloadIcon;
