import Button from "../../../../common/Button";

const RangeButtonGroup = ({ rangeState, onClick }) => {
  return (
    <>
      <Button
        id="week"
        additionStyle={`!p-[5px] border-[#5038BC] truncate ${
          rangeState === "week" ? "" : "bg-transparent text-[#BDBDBD]"
        }`}
        type={rangeState === "week" ? "activeLink" : "ghost"}
        onClick={onClick}
      >
        1 Week
      </Button>
      <Button
        id="month"
        additionStyle={`!p-[5px] border-[#5038BC] truncate ${
          rangeState === "month" ? "" : "bg-transparent text-[#BDBDBD]"
        }`}
        onClick={onClick}
        type={rangeState === "month" ? "activeLink" : "ghost"}
      >
        1 Month
      </Button>
      <Button
        id="six_month"
        additionStyle={`!p-[5px] border-[#5038BC] truncate ${
          rangeState === "six_month" ? "" : "bg-transparent text-[#BDBDBD]"
        }`}
        type={rangeState == "six_month" ? "activeLink" : "ghost"}
        onClick={onClick}
      >
        6 Months
      </Button>
    </>
  );
};

export default RangeButtonGroup;
