import Chart from "chart.js/auto";
import { Spinner } from "@chakra-ui/react";
import { Line } from "react-chartjs-2";

const LineChart = ({ dataset }) => {
  return (
    <div className="px-6 h-[17em] sm:px-6 md:px-8 py-6 font-black">
      {dataset == null ? (
        <div className="w-full h-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : dataset.length === 0 ? (
        <div className="w-full h-full flex justify-center items-center">
          Analytics Data is empty
        </div>
      ) : (
        <Line
          data={{
            labels: dataset.convertedLabel,
            datasets: [
              {
                data: dataset.convertedData,
                backgroundColor: "#5038BC50",
                borderColor: "#5038BC",
                pointRadius: 5,
                pointHoverRadius: 5,
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: {
                ticks: {
                  font: {
                    weight: "bold",
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: false,
                labels: {
                  font: {
                    weight: "bold",
                  },
                },
              },
              tooltip: {
                displayColors: false,
                backgroundColor: "#DED9F2",
                titleColor: "#000",
                titleAlign: "center",
                titleFont: "normal",
                bodyColor: "#000",
                bodyAlign: "center",
                callbacks: {
                  label: function (context) {
                    let num = context.parsed.y;
                    let label = `${num} ${num <= 1 ? "Click" : "Clicks"}`;
                    return label;
                  },
                },
              },
            },
            offset: false,
            height: 550,
            animation: {
              duration: 1000,
            },
          }}
        />
      )}
    </div>
  );
};

export default LineChart;
