import WarningIcon from "../../common/Icon/WarningIcon";
import { QRWithLogo, QRWithoutLogo } from "./Logo";

const Preview = ({ color, filter, withLogo }) => {
  return (
    <div className="lg:w-1/2 max-w-[220px] lg:max-w-[240px] self-center">
      <div className="bg-white flex flex-col items-center p-3 rounded-lg border-[#5038BC33] border-[1px]">
        <div className="font-bold">Preview</div>
        <div
          className="w-[80%] aspect-square mt-3"
          style={{ backgroundColor: color }}
        >
          {withLogo ? (
            <QRWithLogo color={color} filter={filter} />
          ) : (
            <QRWithoutLogo color={color} />
          )}
        </div>
        <div className="mt-3 text-[0.7em] flex gap-2">
          <WarningIcon size={20} /> QR Code preview can&apos;t be used as your
          QR Code
        </div>
      </div>
    </div>
  );
};

export default Preview;
