import { HexColorInput, HexColorPicker } from "react-colorful";
import ToggleSwitch from "../../common/Input/ToggleSwitch";

const ColorPickerSection = ({
  color,
  setColor,
  showColorPicker,
  openColorPickerHandler,
}) => {
  return (
    <div className="flex flex-col gap-3">
      <div>Change QR Color</div>
      <div className="flex box-border rounded-lg border-[#5038BC33] border-[1px] relative">
        <div
          onClick={openColorPickerHandler}
          className={`w-[70px] rounded-l-[0.4rem]`}
          style={{ backgroundColor: color }}
        ></div>
        <div
          className="w-full flex items-center bg-white rounded-lg"
          onClick={openColorPickerHandler}
        >
          <span className="pl-2">#</span>
          <HexColorInput
            color={color}
            onChange={setColor}
            className="h-[52px] outline-none pr-2 rounded-lg w-full"
          />
        </div>
        {showColorPicker && (
          <div className="absolute bottom-[58px] left-0">
            <HexColorPicker
              color={color}
              onChange={setColor}
              onClick={openColorPickerHandler}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const WithLogoOption = ({ withLogo, setWithLogo }) => {
  return (
    <div className="flex flex-col gap-3">
      <div>Show RISTEK&apos;s logo</div>
      <ToggleSwitch
        value={withLogo}
        onChange={() => {
          setWithLogo((state) => !state);
        }}
      />
    </div>
  );
};

const QROption = ({
  color,
  setColor,
  showColorPicker,
  openColorPickerHandler,
  withLogo,
  setWithLogo,
}) => {
  return (
    <div className="flex flex-col gap-5 font-bold lg:w-[50%]">
      <ColorPickerSection
        color={color}
        setColor={setColor}
        showColorPicker={showColorPicker}
        openColorPickerHandler={openColorPickerHandler}
      />
      <WithLogoOption withLogo={withLogo} setWithLogo={setWithLogo} />
    </div>
  );
};

export default QROption;
