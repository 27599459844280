const CopyIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.5H20C21.1 2.5 22 3.4 22 4.5V16.5C22 17.6 21.1 18.5 20 18.5H8C6.9 18.5 6 17.6 6 16.5V4.5C6 3.4 6.9 2.5 8 2.5ZM20 16.5V4.5H8V16.5H20ZM11.5 12.17L13.19 14.43L15.67 11.33L19 15.5H9L11.5 12.17ZM2 20.5V6.5H4V20.5H18V22.5H4C2.9 22.5 2 21.6 2 20.5Z"
        fill="#5038BC"
      />
    </svg>
  );
};

export default CopyIcon
