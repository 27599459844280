import { useStateManagementContext } from "../../../context/StateManagementContext/StateManagementContext";

const Container = ({ drawerOpened, children }) => {
  const { state } = useStateManagementContext();
  return (
    <div
      className={`w-full py-0 px-8 m-auto flex flex-col justify-center items-center transition-{pr} duration-200 min-h-[700px] lg:min-h-[600px] ${
        drawerOpened && "py-0 px-8 lg:pr-[calc(50vw+1rem)] lg:pl-4"
      } ${
        (state === "analytic" || state === "about-retention") &&
        "!min-h-0 !transition-none"
      }`}
    >
      {children}
    </div>
  );
};

export default Container;
