import URLDrawer from "../../common/URLDrawer/URLDrawer";
import { useDrawerContext } from "../../../context/DrawerContext/DrawerContext";
import { useEffect, useState } from "react";
import GenerateQR from "../GenerateQR/GenerateQR";
import Container from "./Container";
import DrawerCloseButton from "../../common/URLDrawer/components/URLDrawerComponents/DrawerCloseButton";
import MobileDrawerOpener from "../../common/URLDrawer/components/URLDrawerComponents/MobileDrawerOpener";
import OpenDrawerLogo from "../../common/URLDrawer/OpenDrawerlogo";
import Analytic from "../Analytic/Analytic";
import { useBackdropContext } from "../../../context/BackdropContext/BackdropContext";
import { useStateManagementContext } from "../../../context/StateManagementContext/StateManagementContext";
import useWindowSize from "../../../hooks/useWindowSize";
import URLShortener from "../URLShotener/URLShortener";
import { useMixpanelClient } from "../../../hooks/useMixpanelClient";
import { useAuthContext } from "../../../context/AuthContext/AuthContext";

export default function Landing() {
  const { drawerOpened, setDrawerOpened } = useDrawerContext();
  const { setBackdropActive } = useBackdropContext();
  const { state } = useStateManagementContext();
  const { width } = useWindowSize();
  const [hasOpen, setHasOpen] = useState(localStorage.getItem("hasOpen"));
  const { user } = useAuthContext();

  useEffect(() => {
    if (
      (state === "analytic" || state === "about-retention") &&
      drawerOpened &&
      width >= 1024
    ) {
      setBackdropActive(true);
    } else {
      setBackdropActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, drawerOpened, width]);

  return (
    <div className='w-full'>
      <Container drawerOpened={drawerOpened}>
        {state === "landing" && <URLShortener />}
        {state === "generate-qr" && <GenerateQR />}
      </Container>
      {(state === "analytic" || state === "about-retention") && <Analytic />}
      {state !== "generate-qr" && state !== "about-retention" && (
        <div className='mt-4 lg:mt-0'>
          <MobileDrawerOpener
            onClick={() => {
              setDrawerOpened(!drawerOpened);
              useMixpanelClient.track("open_my_urls", {
                is_logged_in: user ? "True" : "False",
              });
            }}
          >
            <div className='font-bold text-white'>My URLs</div>
            <div className='text-white'>View</div>
          </MobileDrawerOpener>
        </div>
      )}
      <DrawerCloseButton
        onClick={() => {
          localStorage.setItem("hasOpen", true);
          setHasOpen(true);
          setDrawerOpened(!drawerOpened);
          if (!drawerOpened) {
            useMixpanelClient.track("open_my_urls", {
              is_logged_in: user ? "True" : "False",
            });
          } else {
            useMixpanelClient.track("close_my_urls", {
              is_logged_in: user ? "True" : "False",
            });
          }
        }}
        open={drawerOpened}
      >
        {state === "landing" && !hasOpen && (
          <div className={`${drawerOpened && "hidden"}`}>
            <div className='absolute right-[10px] animate-bounce h-[50px] top-[5px]'>
              <div className='absolute w-[270px] bg-[#DED9F2] right-[100px] top-[10px] rounded-lg text-center h-full flex items-center justify-center'>
                My Url Moved Here
              </div>
              <div className='absolute w-[20px] h-[20px] bg-[#DED9F2] skew-y-[30deg] right-[93px] top-[20px] -rotate-[60deg]'></div>
            </div>
          </div>
        )}
        <OpenDrawerLogo />
      </DrawerCloseButton>
      <URLDrawer />
    </div>
  );
}
