import { useEffect, useState } from "react";
import DetailSummary from "./DetailSummary";
import { useStateManagementContext } from "../../../../../context/StateManagementContext/StateManagementContext";

const DetailSummaryGroup = ({ dataset }) => {
  const [range, setRange] = useState("week");
  const { rangeState } = useStateManagementContext();
  useEffect(() => {
    switch (rangeState) {
      case "six_month":
        setRange("6 months");
        break;
      case "month":
        setRange("month");
        break;
      default:
        setRange("week");
    }
  }, [rangeState]);

  return (
    <>
      <DetailSummary
        name={`Average Click Per ${range}`}
        value={parseFloat((dataset?.avg ?? 0).toFixed(2))}
      />
      <DetailSummary
        name={`Max Click Per ${range}`}
        value={dataset?.max ?? 0}
      />
      <DetailSummary
        name={`Min Clicks Per ${range}`}
        value={dataset?.min ?? 0}
      />
    </>
  );
};

export default DetailSummaryGroup;
